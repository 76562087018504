import VueAxios from "../../js/modules/vue-axios";

export default {
  methods: {
    currencyCode() {
      const currencies = {
        faunatech: "AUD",
        wildlifeacoustics: "USD"
      };

      const siteName = document.querySelector('meta[name="site_name"]').content ?? "wildlifeacoustics";

      return currencies[siteName] ?? "";
    },
    formattedCurrency() {
      const currencies = {
        faunatech: "AUD inc GST",
        wildlifeacoustics: "USD"
      };

      const siteName = document.querySelector('meta[name="site_name"]').content ?? "wildlifeacoustics";

      return currencies[siteName] ?? "";
    },
    formattedPrice(price, includeCurrency = true) {
      if (!price) {
        return null;
      }

      return (
        (includeCurrency ? "$" : "") +
        parseFloat(Math.round(price * 100) / 100).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
      );
    },
    getCart(onSuccess = () => {}) {
      var self = this;
      setTimeout(function() {
        VueAxios.get("/actions/wildlife/shop/get-cart").then(response => {
          let cart = response.data.cart || null;
          if (cart) {
            self.$root.cart = cart;

            onSuccess();
          }
        });
      }, 750);
    },
    getProductPriceByQuantity(product, qty) {
      let price = product.price;
      if (!product.price_tiers) {
        return price;
      }
      product.price_tiers.forEach(function(tier) {
        if (tier.tier === 0) {
          if (tier.min > 0 && tier.max > 0 && product.qty >= tier.min && product.qty <= tier.max) {
            price = tier.unit;
          }
          if (tier.min > 0 && tier.max == 0 && product.qty >= tier.min) {
            price = tier.unit;
          }
        }
      });
      return price;
    }
  }
};
