export default {
  methods: {
    getSiteName() { 
      return document.querySelector('meta[name="site_name"]').content ?? "wildlifeacoustics";
    },
    canUseWebP() {
      var elem = document.createElement("canvas");

      if (!!(elem.getContext && elem.getContext("2d"))) {
        // was able or not to get WebP representation
        return elem.toDataURL("image/webp").indexOf("data:image/webp") == 0;
      }

      // very old browser like IE 8, canvas not supported
      return false;
    },
    backgroundPosition(focalPoint = []) {
      if (focalPoint.length === 2) {
        return {
          "background-position": focalPoint[0] * 100 + "% " + focalPoint[1] * 100 + "%"
        };
      } else {
        return null;
      }
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    setUrl(id, title, url) {
      history.pushState(
        {
          id: id
        },
        title,
        url
      );
    },
    widowProtect(str) {
      // replaces last space in string with &nbsp; to prevent last word from dropping to next line
      return str.replace(/\s([^\s]*)$/, "&nbsp;$1");
    },
    setCookie(name, value, expires, path, domain, secure) {
      document.cookie =
        name +
        "=" +
        escape(value) +
        (expires ? "; expires=" + expires : "") +
        (path ? "; path=" + path : "") +
        (domain ? "; domain=" + domain : "") +
        (secure ? "; secure" : "");
    },
    getCookie(name) {
      var cookie = " " + document.cookie;
      var search = " " + name + "=";
      var setStr = null;
      var offset = 0;
      var end = 0;
      if (cookie.length > 0) {
        offset = cookie.indexOf(search);
        if (offset != -1) {
          offset += search.length;
          end = cookie.indexOf(";", offset);
          if (end == -1) {
            end = cookie.length;
          }
          setStr = unescape(cookie.substring(offset, end));
        }
      }
      return setStr;
    },
    getSiteName() {
      return document.querySelector('meta[name="site_name"]').content ?? 'Wildlife Acoustics';
    },
    getSiteFullName() {
      return document.querySelector('meta[name="site_full_name"]').content ?? 'Wildlife Acoustics'
    },
    delCookie(name) {
      document.cookie = name + "=" + "; expires=Thu, 01 Jan 1970 00:00:01 GMT";
    }
  }
};
