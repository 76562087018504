export default {
  methods: {
    matchHeight(self, refName, minWidth) {
      let el = self.$refs[refName];
      if (el !== undefined) {
        this.setHeight(el, minWidth);
        window.addEventListener("resize", () => {
          this.setHeight(el, minWidth);
        });
      }
    },
    getMaxHeight(el) {
      let maxH = 0;
      for (var i = 0; i < el.length; i++) {
        let curH = el[i].clientHeight;
        if (curH > maxH) {
          maxH = curH;
        }
      }
      return maxH;
    },
    setHeight(el, minWidth) {
      let maxH = this.getMaxHeight(el);
      for (var i = 0; i < el.length; i++) {
        if (window.innerWidth >= minWidth) {
          el[i].style.minHeight = maxH + "px";
        } else {
          el[i].style.minHeight = "";
        }
      }
    }
  }
};
